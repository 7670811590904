/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import { is_logged_in } from "../utilities/auth";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Productar = (props) => {
  const [loading, setLoading] = useState(true);
  const [products, setProduct] = useState([]);
  const [act_prd, setActProduct] = useState([]);
  const router = useHistory();

  useEffect(async () => {
    setLoading(true);
    setLoading(false);

    // console.log(act_prd)
  }, []);
  const changeImg1 = async () => {
    const imageUrl = require('../assets/img/1.jpg');
    const element = document.getElementById('modelsudip');
    if (element) {
      element.style.backgroundImage = `url(${imageUrl})`;
    }
  }
  const changeImg2 = async () => {
    const imageUrl = require('../assets/img/3.jpg');
    const element = document.getElementById('modelsudip');
    if (element) {
      element.style.backgroundImage = `url(${imageUrl})`;
    }
  }
  const changeImg3 = async () => {
    const imageUrl = require('../assets/img/4.jpg');
    const element = document.getElementById('modelsudip');
    if (element) {
      element.style.backgroundImage = `url(${imageUrl})`;
    }
  }

  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={70} />
      </Override>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Product AR</title>
        </Helmet>
        <Header />
        <div class="clearfix"></div>

        {/* <article class="inner_banner">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1>1,000 Home design professionals around the world</h1>
				</div>
			</div>
		</div>
	</article> */}

        <div class="clearfix"></div>
        <div class="row">
		<div class="col-md-6">
        <model-viewer
          id="modelsudip"
          src="https://www.novistaa.com/admin/uploads/3d_model/sofa_03_4k.gltf"
          alt="A 3D model"
          ar
          auto-rotate
          camera-controls
        ></model-viewer>
		</div>
		<div class="product_color_sec col-md-6">
			<label>Change Background</label>
			<div class="product_color_list">
				<ul>
					<li onClick={() => changeImg1()}><a href="javascript:void(0);" class="change_background" data-href="images/1.jpg"><img src={require("../assets/img/1.jpg")}/></a><span></span></li>
					{/* <li><a href="javascript:void(0);" class="change_background" data-href="images/2.jpg"><img src={require("../assets/img/2.jpg")}/></a><span></span></li> */}
					<li onClick={() => changeImg2()}><a href="javascript:void(0);" class="change_background" data-href="images/3.jpg"><img src={require("../assets/img/3.jpg")}/></a><span></span></li>
					<li onClick={() => changeImg3()}><a href="javascript:void(0);" class="change_background" data-href="images/4.jpg"><img src={require("../assets/img/4.jpg")}/></a><span></span></li>
				</ul>
				<div class="col footer_col">
					<div class="footer_menu">
						<h4>Instructions</h4>
						<ul>
							<li><a href="javascript:void(0);">1.Use Mouse to handle the 3D Model</a></li>
							<li><a href="javascript:void(0);">2.Use Mouse Wheel to Zoom in or out</a></li>
							<li><a href="javascript:void(0);">3.Use Shift+Mouse Left Key to place the Model</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
        

        <div class="clearfix"></div>

        <Footer />
      </div>
    );
  }
};

export default Productar;
