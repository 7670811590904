/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import ReactGa from "react-ga";
import Header from "./components/Header/Header";
import Caraousel from "./components/Carousel/carouselSlider";
import axios from "./utilities/axios";
import { api_url } from "./utilities/constants";
import { api_url_main } from "./utilities/constants";
import { pxy_url } from "./utilities/constants";
import lanStyles from "./components/LanguageSlider/languageSlider.module.css";
import Slider from "./components/Slider/Slider";
import styled from "styled-components";
// import MediaScreen from "./components/CarouselCard/MediaScreen/MediaScreen";
import { useHistory } from "react-router-dom";
import LanguageSlider from "./components/LanguageSlider/languageSlider";
// import Category from "./pages/categorySlug";
import Footer from "./components/Footer/Footer";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import OwlCarousel from "@ntegral/react-owl-carousel";

// import { toast } from "react-toastify";

// import { is_logged_in, loginCheck } from "./utilities/auth";
import RecentContinue from "./components/RecentContinue";
import Context from "./context_api/global";
import RecentCard from "./components/RecentContinue/RecentCard";
import { Helmet } from "react-helmet";
import { capitalize } from "@mui/material";
import LiveChat from "./components/LiveChat";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Home = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [roomtype, setRoomTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [brands, setBrands] = useState();
  const [titlechg, setTitlechg] = useState();
  const [category, setCategory] = useState("media");
  const router = useHistory();
  const { continueData, contentContinue } = useContext(Context);
  const user = localStorage.getItem("user", null);
  // console.log('USER -> ', user);
  useEffect(() => {
    const fetchContents = async (response) => {
      setLoading(true);
      // setLocation(window.location.pathname);
      try {
        const isAuthenticated = localStorage.getItem("loginToken");
        if (isAuthenticated) contentContinue();

        const body = {
          limit: 10,
        };
        const sixbody = {
          limit: 10,
        };
        // localStorage.setItem("user", "3");
        // localStorage.setItem("user_email", "dip@gmail.com");
        // localStorage.setItem("name", "Dip Karmakar");
        // localStorage.setItem("phone_no", "7003305766");
        const res = await fetch(`${pxy_url + api_url_main}/roomtypes`, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            // "Access-Control-Allow-Headers":"privatekey, Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin" : "*",
          },
          body: JSON.stringify(body),
        });
        const roomtypes = await res.json();
        setRoomTypes(await roomtypes);
        // Blogs
        const blogres = await fetch(`${pxy_url + api_url_main}/blogs`, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin" : "*",
          },
          body: JSON.stringify(body),
        });
        const blogs = await blogres.json();
        setBlogs(await blogs);

        // Projects
        const projectres = await fetch(`${pxy_url + api_url_main}/projects`, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin" : "*",
          },
          body: JSON.stringify(body),
        });
        const projects = await projectres.json();
        setProjects(await projects);

        // Brands
        const brandres = await fetch(`${pxy_url + api_url_main}/brands`, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin" : "*",
          },
          body: JSON.stringify(body),
        });
        const brands = await brandres.json();
        setBrands(await brands);
        // console.log(roomtypes);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // console.log(err);
      }
    };
    fetchContents();
  }, [props.match.params.id]);

  ReactGa.initialize("UA-206471971-2");
  ReactGa.pageview(window.location.pathname + window.location.search);

  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={80} />
      </Override>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito | Exciting Deals Daily</title>
        </Helmet>
        <Header />
        <article class="banner">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 banner_left_main">
                <Carousel>
                  <div>
                    <img src="https://miragesoftwares.com/html/prelito/images/banner2.png" />
                    {/* <p className="legend">Legend 1</p> */}
                    {/* <div class="banner_left">
                      <div class="banner_left_form">
                        <h1>Make your dream home a reality</h1>
                        <form action="/">
                          <div class="button_with_google">
                            <div class="button_with_google_left">
                                <img
                                  src={require("./assets/img/g.png")}
                                  width="25"
                                />
                            </div>
                            <div class="button_with_google_right">
                              <span>Continue with Google</span>
                            </div>
                          </div>
                          <div class="button_line">
                            <span>or</span>
                          </div>
                          <div class="form-group">
                            <input
                              list="data"
                              type="email"
                              id="email"
                              placeholder="Email"
                            />
                            <Link to="/login"><button type="button" class="top_signin_button">Sign In with email</button></Link>
                          </div>
                          <div class="terms_link">
                            <span>By signing up, signing in or continuing. I agree to Prelite <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                          </div>
                        </form>
                      </div>
                      <div class="col-lg-10 banner_hire">
                <span>Hire a Pro :</span> 
                <a href="javascript:void(0);">Contractors</a>
                <a href="javascript:void(0);">Interior Designer</a> 
                <a href="javascript:void(0);">Architects</a>
                <a href="javascript:void(0);"><b>see more</b></a>
              </div>
                    </div> */}
                  </div>
                  <div>
                    <img src="https://miragesoftwares.com/html/prelito/images/banner3.png" />
                    {/* <div class="banner_left">
                      <div class="banner_left_form">
                        <h1>Make your dream home a reality</h1>
                        <form action="/">
                          <div class="button_line">
                          </div>
                          <div class="form-group">
                            <input
                              list="data"
                              type="email"
                              id="email"
                              placeholder="Email"
                            />
                            <Link to="/login"><button type="button" class="top_signin_button">Sign In with email</button></Link>
                          </div>
                          <div class="terms_link">
                            <span>By signing up, signing in or continuing. I agree to Prelite <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <img src="https://miragesoftwares.com/html/prelito/images/banner4.png" />
                    {/* <div class="banner_left">
                      <div class="banner_left_form">
                        <h1>Make your dream home a reality</h1>
                        <form action="/">
                          <div class="button_line">
                          </div>
                          <div class="form-group">
                            <input
                              list="data"
                              type="email"
                              id="email"
                              placeholder="Email"
                            />
                            <Link to="/login"><button type="button" class="top_signin_button">Sign In with email</button></Link>
                          </div>
                          <div class="terms_link">
                            <span>By signing up, signing in or continuing. I agree to Prelite <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <img src="https://miragesoftwares.com/html/prelito/images/banner5.png" />
                    {/* <div class="banner_left">
                      <div class="banner_left_form">
                        <h1>Make your dream home a reality</h1>
                        <form action="/">
                          <div class="button_line">
                          </div>
                          <div class="form-group">
                            <input
                              list="data"
                              type="email"
                              id="email"
                              placeholder="Email"
                            />
                            <Link to="/login"><button type="button" class="top_signin_button">Sign In with email</button></Link>
                          </div>
                          <div class="terms_link">
                            <span>By signing up, signing in or continuing. I agree to Prelite <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                          </div>
                        </form>
                      </div>
                    </div> */}
                  </div>
                </Carousel>

                {/* <div class="banner_left">
              <div class="col-lg-7 banner_left_form">
                <h1>Make your dream home a reality</h1>
                <form action="/">
                  <div class="button_with_google">
                    <div class="button_with_google_left">
                      <Link to="/">
                          <img
                          src={require("./assets/img/g.png")}
                          width="25"
                          />
                      </Link>
                    </div>
                    <div class="button_with_google_right">
                      <span>Continue with Google</span>
                    </div>
                  </div>
                  <div class="button_line">
                    <span>or</span>
                  </div>
                  <div class="form-group">
                    <input
                    list="data"
                    type="email"
                    id="email"
                    placeholder="Email"
                    />
                    <Link to="/login"><button type="button" class="top_signin_button">Sign In with email</button></Link>
                  </div>
                  <div class="terms_link">
                    <span>By signing up, signing in or continuing. I agree to Prelite <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                  </div>
                </form>
              </div> */}
                {/* <div class="col-lg-10 banner_hire">
                <span>Hire a Pro :</span> 
                <a href="javascript:void(0);">Contractors</a>
                <a href="javascript:void(0);">Interior Designer</a> 
                <a href="javascript:void(0);">Architects</a>
                <a href="javascript:void(0);"><b>see more</b></a>
              </div> */}
                {/* </div> */}
              </div>
              {/* <div class="col-lg-4 banner_right">
            <div class="banner_right_icon">
              <Link to="/">
                  <img
                  src={require("./assets/img/white-icon.png")}
                  />
              </Link>
              <span><b>Prelite</b> Pro.</span>
            </div>
            <h2>Join Million of Home Professionals</h2>
            <p>Get the all-in-one tools for marketing. CRM and Project managment.</p>
            <div class="banner_left_form banner_right_form">
              <form action="/">
                <div class="form-group">
                <input
                    list="data"
                    type="email"
                    id="email"
                    placeholder="Email"
                    />
                  <Link to="/signup"><button type="button" class="top_signin_button">Sign In with email</button></Link>
                </div>
                <div class="terms_link">
                  <span>By signing up, signing in or continuing. I agree to Prelite <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</span>
                </div>
              </form>
            </div>
          </div> */}
            </div>
          </div>
        </article>

        <div class="clearfix"></div>

        <section class="section product_type">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 product_type_heading">
                <h2>Design ideas by room type</h2>
              </div>
              <OwlCarousel
                className="owl-theme"
                margin={5}
                autoWidth={true}
                dots={false}
                nav
                navText={['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']}
              >
              {roomtype.data.map((type, index) => (
                <div class="col-lg-3 product_part">
                  <div class="product_type_image">
                    <Link to={`/photos-list/roomtype/${type.id}`}><img
                      src={roomtype.imagePath + type.image}
                    /></Link>
                  </div>
                  <div class="product_type_name">
                    <h4>{type.name}</h4>
                  </div>
                </div>
              ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <div class="clearfix"></div>

        {/* <section class="section product_type latest_stories">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 product_type_heading">
            <h2>Latest Stories</h2>
            <Link to="/stories">See All</Link>
          </div>
          {blogs.data.map((blog, index) => (
          <div class="col-lg-3 product_part">
            <div class="product_type_image">
            <Link to={`/stories-details/${blog.id}`}><img src={ blogs.imagePath + blog.image} /></Link>
            </div>
            <div class="product_type_name">
              <span>popular</span>
              <p>{blog.name}</p>
            </div>
          </div>
          ))}
        </div>
      </div>
    </section> */}

        {/* <div class="clearfix"></div> */}
        <div class="clearfix"></div>

        <section class="section product_type help_buying">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 product_type_heading">
                <h2>Quick moving deals</h2>
                <Link to="/products">See All</Link>
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <Link to="/products">
                    <img src={require("./assets/img/deal-1.png")} />
                  </Link>
                </div>
                {/* <div class="product_type_name">
              <h4>Here's How to Choose The Right Sofa</h4>
            </div> */}
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <Link to="/products">
                    <img src={require("./assets/img/deal-2.png")} />
                  </Link>
                </div>
                {/* <div class="product_type_name">
              <h4>Here's How to Choose The Right Sofa</h4>
            </div> */}
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <Link to="/products">
                    <img src={require("./assets/img/deal-3.png")} />
                  </Link>
                </div>
                {/* <div class="product_type_name">
              <h4>Here's How to Choose The Right Sofa</h4>
            </div> */}
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <Link to="/products">
                    <img src={require("./assets/img/deal-4.png")} />
                  </Link>
                </div>
                {/* <div class="product_type_name">
              <h4>Here's How to Choose The Right Sofa</h4>
            </div> */}
              </div>
              {/* <div class="col-lg-2 product_part">
            <div class="product_type_image">
              <img src={require("./assets/img/work-from-home.jpg")} />
            </div>
            <div class="product_type_name">
              <h4>How To Buy A Perfect Bed For Your Room</h4>
            </div>
          </div>
          <div class="col-lg-2 product_part">
            <div class="product_type_image">
              <img src={require("./assets/img/kids-room.jpg")} />
            </div>
            <div class="product_type_name">
              <h4>Your Guide To Buy The Perfect Mattress</h4>
            </div>
          </div> */}
            </div>
          </div>
        </section>
        {/* <section class="product_page_section">
          <div class="container">
            <div class="row"></div>
            <div class="similar_product_list product_page_product_list top_deal">
              <div class="similar_product_list_heading">
                <span>Quick moving deals</span>
                <Link to="/products">See All</Link>
              </div>
              <div class="similar_product_list_sec">
                <div class="similar_product_list_box">
                  <Link to="/products">
                    <div class="similar_product_list_box_image">
                      <img src={require("./assets/img/deal-1.png")} />
                    </div>
                  </Link>
                </div>
                <div class="similar_product_list_box">
                  <Link to="/products">
                    <div class="similar_product_list_box_image">
                      <img src={require("./assets/img/deal-2.png")} />
                    </div>
                  </Link>
                </div>
                <div class="similar_product_list_box">
                  <Link to="/products">
                    <div class="similar_product_list_box_image">
                      <img src={require("./assets/img/deal-3.png")} />
                    </div>
                  </Link>
                </div>
                <div class="similar_product_list_box">
                  <Link to="/products">
                    <div class="similar_product_list_box_image">
                      <img src={require("./assets/img/deal-4.png")} />
                    </div>
                  </Link>
                </div>
                <div class="similar_product_list_box">
                  <Link to="/products">
                    <div class="similar_product_list_box_image">
                      <img src={require("./assets/img/deal-4.png")} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section class="section product_type latest_stories browser_photo_style">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 product_type_heading">
                <h2>On Sale Tomorrow</h2>
                <Link to="/projects">See All</Link>
              </div>
              <OwlCarousel
                className="owl-theme"
                margin={5}
                autoWidth={true}
                dots={false}
                nav
                navText={['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']}
              >
              {projects.data.map((proj, index) => (
                <div class="col-lg-3 product_part">
                  {/* <div class="photo_style_box"> */}
                    <div class="product_type_image">
                      <Link to={`/photos-list/style/${proj.id}`}><img src={projects.imagePath + proj.image} /></Link>
                    </div>
                    <div class="product_type_name">
                      <p>{proj.name}</p>
                    </div>
                  {/* </div> */}
                </div>
              ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        {/* <div class="clearfix"></div>

    <section class="section product_type brand_choose">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 product_type_heading">
            <h2>Top Brand to choose from</h2>
            <Link to="/brands">See All</Link>
          </div>
          {brands.data.map((brand, index) => (
          <div class="col-lg-2 product_part">
            <div class="brand_image_box">
              <div class="product_type_image">
              <Link to={`/photos-list/brand/${brand.id}`}><img src={require("./assets/img/kitchen.jpg")} /></Link>
              </div>
              <div class="brand_logo">
                <img src={ brands.imagePath + brand.image} />
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </section> */}

        <div class="clearfix"></div>

        <section class="section product_type help_buying">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 product_type_heading">
                <h2>Need help to buying</h2>
                <a href="javascript:void(0);">See All</a>
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <img src={require("./assets/img/living-room.jpg")} />
                </div>
                <div class="product_type_name">
                  <h4>Here's How to Choose The Right Sofa</h4>
                </div>
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <img src={require("./assets/img/work-from-home.jpg")} />
                </div>
                <div class="product_type_name">
                  <h4>How To Buy A Perfect Bed For Your Room</h4>
                </div>
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <img src={require("./assets/img/kids-room.jpg")} />
                </div>
                <div class="product_type_name">
                  <h4>Your Guide To Buy The Perfect Mattress</h4>
                </div>
              </div>
              <div class="col-lg-3 product_part">
                <div class="product_type_image">
                  <img src={require("./assets/img/living-room.jpg")} />
                </div>
                <div class="product_type_name">
                  <h4>Here's How to Choose The Right Sofa</h4>
                </div>
              </div>
              {/* <div class="col-lg-2 product_part">
            <div class="product_type_image">
              <img src={require("./assets/img/work-from-home.jpg")} />
            </div>
            <div class="product_type_name">
              <h4>How To Buy A Perfect Bed For Your Room</h4>
            </div>
          </div>
          <div class="col-lg-2 product_part">
            <div class="product_type_image">
              <img src={require("./assets/img/kids-room.jpg")} />
            </div>
            <div class="product_type_name">
              <h4>Your Guide To Buy The Perfect Mattress</h4>
            </div>
          </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
};

export default Home;
