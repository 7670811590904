/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import { is_logged_in } from "../utilities/auth";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Products = (props) => {
  const [loading, setLoading] = useState(true);
  const [products, setProduct] = useState([]);
  const [act_prd, setActProduct] = useState([]);
  const [brands, setBrands] = useState();
  const [maincat, setCatelogs] = useState();
  const router = useHistory();

  useEffect(async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/products`;
    let data = { post_per_page: 10 };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson.data);
    setActProduct(responseJson.data);
    setProduct(responseJson);
    // Brands
    const sbody = {
      limit: 4,
    };
    const brandres = await fetch(`${pxy_url + API_URL}/brands`, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(sbody),
    });
    const brands = await brandres.json();
    setBrands(await brands);

    // Catelouges
    const catelougres = await fetch(`${pxy_url + API_URL}/catalouges`, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(sbody),
    });
    const catelogs = await catelougres.json();
    // console.log(catelogs);
    setCatelogs(await catelogs);

    setLoading(false);

    // console.log(act_prd)
  }, []);

  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={70} />
      </Override>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Products</title>
        </Helmet>
        <Header />
        <div class="clearfix"></div>

        {/* <article class="inner_banner">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h1>1,000 Home design professionals around the world</h1>
				</div>
			</div>
		</div>
	</article> */}

        <div class="clearfix"></div>

        <section class="section product_list">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 product_list_left">
                <div class="filter_heading">
                  <h4>Filter items</h4>
                </div>
                <div class="filter_type">
                  <div class="filter_type_top">
                    <div class="filter_category">
                      <h4>Categories</h4>
                    </div>
                    <div class="filter_search">
                    {/* <img src={require("../assets/img/search-Icon.png")}/> */}
                    </div>
                  </div>
                  <div class="filter_type_list">
                    <ul>
                    {maincat.data.map((cat, index) => (
                      <li>
                        <label class="check_label">{ cat.title}
                        <input type="checkbox" id="01" name="01" value="{ cat.title}" />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div class="filter_type">
                  <div class="filter_type_top">
                    <div class="filter_category">
                      <h4>Brands</h4>
                    </div>
                    <div class="filter_search">
                    {/* <img src={require("../assets/img/search-Icon.png")}/> */}
                    </div>
                  </div>
                  <div class="filter_type_list">
                    <ul>
                    {brands.data.map((brand, index) => (
                      <li>
                        <label class="check_label">{ brand.name}
                        <input type="checkbox" id="01" name="01" value="{ brand.name}"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div class="filter_type">
                  <div class="filter_type_top">
                    <div class="filter_category">
                      <h4>price</h4>
                    </div>
                    <div class="filter_search">
                    <img src={require("../assets/img/search-Icon.png")}/>
                    </div>
                  </div>
                  <div class="filter_type_list">
                    <ul>
                      <li>
                        <label class="check_label">Under ₹ 10,000
                        <input type="checkbox" id="01" name="01" value="Brand # 01 (200)"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="check_label">₹ 10,000 to ₹ 20,000
                        <input type="checkbox" id="02" name="02" value="Categories # 02"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="check_label">₹ 20,000 to ₹ 30,000
                        <input type="checkbox" id="03" name="03" value="Categories # 03"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="check_label">₹ 30,000 to ₹ 40,000
                        <input type="checkbox" id="04" name="04" value="Categories # 04"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="filter_type">
                  <div class="filter_type_top">
                    <div class="filter_category">
                      <h4>Style</h4>
                    </div>
                    <div class="filter_search">
                    <img src={require("../assets/img/search-Icon.png")}/>
                    </div>
                  </div>
                  <div class="filter_type_list">
                    <ul>
                      <li>
                        <label class="check_label">Modern (200)
                        <input type="checkbox" id="01" name="01" value="Brand # 01 (200)"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="check_label">Traditional (200)
                        <input type="checkbox" id="02" name="02" value="Categories # 02"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="check_label">Contemporary (200)
                        <input type="checkbox" id="03" name="03" value="Categories # 03"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="check_label">Transitional (200)
                        <input type="checkbox" id="04" name="04" value="Categories # 04"/>
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div class="col-lg-9 product_list_right">
                <div class="product_page_product_list product_details_similar">
                  
                {act_prd.map((prod, index) => {
        			  return (
                  <div class="similar_product_list_box">
                    {(prod.threed_model != '' ? 
                      (<div class="short_ar">
                      <a href={`/productar`} class="clip-AR-icon clip_ar_div" data-modal="vip-3d-icon-gallery-popup" id="vip-ar-view">
                          <img src="https://ii1.pepperfry.com/images/svg/icon-ar-21.svg" data-pid="1757416" data-lms="lms_clip_ar_view" data-url="" alt="AR Icon" width="24" height="24px" />
                      </a>
                      </div>) 
                      : ('') 
                    )}
                    <div class="short_wishlist">
                      <i class="fa-solid fa-heart"></i>
                    </div>
                    <Link to={`/product-details/${prod.id}`}>
                      <div class="similar_product_list_box_image">
                      <img src={ products.imagePath + prod.product_image}/>
                      </div>
                      <div class="similar_product_list_box_content">
                        <p>{ prod.product_name }</p>
                        <div class="prodcut_price">
                          <span class="current_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.special_price }</span>
                          <span class="old_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.base_price }</span>
                          <span class="flat_off">{100-(prod.special_price /prod.base_price) *100} %</span>
                        </div>
                        <p>{ prod.labels }</p>
                        <p>Ship in 4 days</p>
                      </div>
                    </Link>
                  </div>
                  );
      					})}

                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="clearfix"></div>

        <Footer />
      </div>
    );
  }
};

export default Products;
