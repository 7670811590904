import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterStyles from "./Footer.module.css";
// import $ from "jquery";

export default class Footer extends Component {
  render() {
    return (
      <>
        {/* <div class="clearfix"></div> */}

        <footer class="footer">
            <div class="footer_top">	
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 footer_sec">
                            <h2>More from the Store</h2>
                            <div class="row">
                                <div class="col footer_col">
                                    <div class="footer_menu">
                                        <h4>Furniture</h4>
                                        <ul>
                                            <li><Link to="/products">Sofas & Recliners</Link></li>
                                            <li><Link to="/products">Seating</Link></li>
                                            <li><Link to="/products">Chairs</Link></li>
                                            <li><Link to="/products">Beds</Link></li>
                                            <li><Link to="/products">Cabinetry</Link></li>
                                            <li><Link to="/products">Tables</Link></li>
                                            <li><Link to="/products">Dining</Link></li>
                                            <li><Link to="/products">Kids & Teens</Link></li>
                                            <li><Link to="/products">Home Office</Link></li>
                                            <li><Link to="/products">Sofa Chairs</Link></li>
                                            <li><Link to="/products">Entertainment Units</Link></li>
                                            <li><Link to="/products">Outdoor</Link></li>
                                            <li><Link to="/products">Bar Furniture</Link></li>
                                            <li><Link to="/products">Furniture & Home Services</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col footer_col">
                                    <div class="footer_menu">
                                        <h4>Sofas & Recliners</h4>
                                        <ul>
                                            <li><Link to="/products">By Size</Link></li>
                                            <li><Link to="/products">By Type</Link></li>
                                            <li><Link to="/products">By Style</Link></li>
                                            <li><Link to="/products">By Material</Link></li>
                                            <li><Link to="/products">Sofa Chairs</Link></li>
                                        </ul>
                                    </div>
                                    <div class="footer_menu">
                                        <h4>Decor</h4>
                                        <ul>
                                            <li><Link to="/products">Wall Shelves & Cabinets</Link></li>
                                            <li><Link to="/products">Clocks</Link></li>
                                            <li><Link to="/products">Wall Art</Link></li>
                                            <li><Link to="/products">Table Decor</Link></li>
                                            <li><Link to="/products">Spiritual</Link></li>
                                            <li><Link to="/products">Home Garden</Link></li>
                                            <li><Link to="/products">Table Ware</Link></li>
                                            <li><Link to="/products">Kids Decor</Link></li>
                                            <li><Link to="/products">Mirrors</Link></li>
                                            <li><Link to="/products">Photo Frames</Link></li>
                                            <li><Link to="/products">Accent Furniture</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col footer_col">
                                    <div class="footer_menu">
                                        <h4>Beds</h4>
                                        <ul>
                                            <li><Link to="/products">By Size</Link></li>
                                            <li><Link to="/products">By Storage</Link></li>
                                            <li><Link to="/products">Kids & Teens</Link></li>
                                            <li><Link to="/products">By Material</Link></li>
                                            <li><Link to="/products">By Style</Link></li>
                                            <li><Link to="/products">Mattresses</Link></li>
                                        </ul>
                                    </div>
                                    <div class="footer_menu">
                                        <h4>Furnishing</h4>
                                        <ul>
                                            <li><Link to="/products">Bed Linen</Link></li>
                                            <li><Link to="/products">Blankets & Comforters</Link></li>
                                            <li><Link to="/products">Carpets</Link></li>
                                            <li><Link to="/products">Curtains</Link></li>
                                            <li><Link to="/products">Cushion & Covers</Link></li>
                                            <li><Link to="/products">Bath Linen</Link></li>
                                            <li><Link to="/products">Kids Furnishing</Link></li>
                                            <li><Link to="/products">Home Services</Link></li>
                                            <li><Link to="/products">Flooring</Link></li>
                                            <li><Link to="/products">Table Linen</Link></li>
                                            <li><Link to="/products">Essentials</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col footer_col">
                                    <div class="footer_menu">
                                        <h4>Cabinetry</h4>
                                        <ul>
                                            <li><Link to="/products">Wardrobes</Link></li>
                                            <li><Link to="/products">ShoeRacks</Link></li>
                                            <li><Link to="/products">Storage</Link></li>
                                            <li><Link to="/products">Entertainment Units</Link></li>
                                            <li><Link to="/products">Bar</Link></li>
                                            <li><Link to="/products">Kids Storage</Link></li>
                                        </ul>
                                    </div>
                                    <div class="footer_menu">
                                        <h4>Lighting</h4>
                                        <ul>
                                            <li><Link to="/products">Floor Lamps</Link></li>
                                            <li><Link to="/products">Chandeliers</Link></li>
                                            <li><Link to="/products">Wall Lights</Link></li>
                                            <li><Link to="/products">Smart Lights</Link></li>
                                            <li><Link to="/products">Festive Lights</Link></li>
                                            <li><Link to="/products">Lampshades</Link></li>
                                            <li><Link to="/products">Celling Lights</Link></li>
                                            <li><Link to="/products">LED Lights</Link></li>
                                            <li><Link to="/products">Outdoor Lights</Link></li>
                                            <li><Link to="/products">Table Lamps</Link></li>
                                            <li><Link to="/products">Kids Lighting</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col footer_col">
                                    <div class="footer_menu">
                                        <h4>Appliances</h4>
                                        <ul>
                                            <li><Link to="/products">Mixers & Processors</Link></li>
                                            <li><Link to="/products">Breakfast Appliances</Link></li>
                                            <li><Link to="/products">Chimneys</Link></li>
                                            <li><Link to="/products">Built-in Appliances</Link></li>
                                            <li><Link to="/products">Heating & Cooling Appliances</Link></li>
                                            <li><Link to="/products">Cleaning</Link></li>
                                            <li><Link to="/products">Refrigerators</Link></li>
                                            <li><Link to="/products">OTGs</Link></li>
                                            <li><Link to="/products">Food Makers</Link></li>
                                            <li><Link to="/products">Cooklops</Link></li>
                                            <li><Link to="/products">Bar Appliances</Link></li>
                                            <li><Link to="/products">Purifiers</Link></li>
                                            <li><Link to="/products">Laundry</Link></li>
                                            <li><Link to="/products">Televisions</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_bottom">
                <div class="container">
                    <div class="row">
                        <div class="col footer_col footer_logo">
                        <Link to="/">
                            <img
                            src={require("../../assets/img/logo-white.png")}
                            alt="logo"
                            />
                        </Link>
                        </div>
                        <div class="col footer_col footer_bottom_menu">
                            <div class="footer_menu">
                                <h4>Useful links</h4>
                                <ul>
                                    <li><Link to="/about-us">About us</Link></li>
                                    <li><Link to="/our-blogs">Our blogs</Link></li>
                                    {/* <li><Link to="/products">Brands</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div class="col footer_col footer_bottom_menu">
                            <div class="footer_menu">
                                <h4>Partner</h4>
                                <ul>
                                    <li><Link to="/sell-with-us">Sell with us</Link></li>
                                    {/* <li><Link to="/products">Become a Pro.</Link></li> */}
                                    <li><Link to="/login">Login to your a/c</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col footer_col footer_bottom_menu">
                            <div class="footer_menu">
                                <h4>Need Help</h4>
                                <ul>
                                    <li><Link to="/contact-us">Contact us</Link></li>
                                    <li><Link to="/refund-policy">Returns & Refund</Link></li>
                                    <li><Link to="/faq">FAQs</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 footer_bottom_menu downloada_app">
                            <div class="footer_menu">
                                <h4>Download app</h4>
                                <Link to="/">
                                    <img
                                    src={require("../../assets/img/ios-app-store.png")}
                                    alt="logo"
                                    />
                                </Link>
                                <Link to="/">
                                    <img
                                    src={require("../../assets/img/android-play-store.png")}
                                    alt="logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-3 copyright">
                            <span>Copyright© Prelito 2023</span>
                        </div>
                        <div class="col-lg-9 footer_last_menu">
                            <ul>
                                {/* <li><Link to="/products">Buy In Bulk </Link></li>
                                <li><Link to="/products">Write A Testimonial </Link></li>
                                <li><Link to="/products">Site Map </Link></li> */}
                                <li><Link to="/terms-of-use">Terms Of Use </Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy </Link></li>
                                <li><Link to="/data-security">Your Data & Security</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
      </>
    );
  }
}
