/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
// import headerStyles from "./Header.module.css";
import WebStyle from "../../Common.css";
import WebResponsiveStyle from "../../Commonresponsive.css";
import { IoSearch } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { loginCheck, setCookies } from "../../utilities/auth";
import axios from "../../utilities/axios";
import { api_url } from "../../utilities/constants";
import Context from "../../context_api/global";
import LiveChat from "../LiveChat";
import { API_HOST_URL } from "../../config";
import { pxy_url } from "../../utilities/constants";
import { api_url_main as API_URL } from "../../utilities/constants";
import $ from "jquery";
import { Button, Navbar, NavDropdown, Container, Row, Col, Dropdown} from 'react-bootstrap'
//import { propTypes } from "react-bootstrap/esm/Image";

export default function Header(props) {
  const [menus, setMenuData] = useState([]);
  const [show, setShow] = useState();
  const [carts, setCartData] = useState();
  // const [curPlan, setCurPlan] = useState([]);
  // const [searchedData, setSearchData] = useState([]);
  const [isloggedin, setLoginStatus] = useState(false);
  const router = useHistory();
  const { search_text, handletextChange } = useContext(Context);
  const path = window.location.pathname;
  const { userData } = useContext(Context);
  // console.log('path -> ', path)
  useEffect(async () => {
    const fetchCategory = async () => {
      setLoginStatus(await loginCheck());
      // const fetchCategory = async () => {
      //   const response = await axios.get(`${api_url}/content/category/`);
      //   const data = await response.data;
      //   setMenuData(data);
      //   setLoginStatus(await loginCheck());
    };
    fetchCategory();

    const menu = `${pxy_url + API_URL}/allMenus`;
    // let user_id = localStorage.getItem("user");
    // let databody = {user_id:user_id};

    const menuResponse = await fetch(menu, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin" : "*",
      },
    });

    const menuresponseJson = await menuResponse.json();
    // console.log(menuresponseJson);
    setMenuData(menuresponseJson);

  }, []);

  const getSearchData = async (val) => {
    handletextChange(val);
    router.push(`/search`);
  };
  const handleLogout = async () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("user");
    localStorage.removeItem("user_email");
    localStorage.removeItem("name");
    localStorage.removeItem("phone_no");
    setCookies("");
    router.push("/login");
  }
  return (
    <>
      <LiveChat />
      <header class="header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 header_top">
              <div class="mobile_hamburger">
                <button type="button" class="hamburger_menu" onclick="openNav()">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <div class="padding_horizontal logo">
                <Link to="/"><img
                  src={require("../../assets/img/new_logo.png")}
                  alt="logo"
                />
                </Link>
              </div>
              <div class="padding_horizontal header_search">
                <form action>
                  <div class="col-lg-12">
                    <div class="form-group search_top">
                      <input
                        list="data"
                        type="search"
                        value={search_text}
                        onClick={() => {
                          router.push(`/search`);
                        }}
                        onChange={(e) => getSearchData(e.target.value)}
                        id="search"
                        placeholder="Search Products"
                        autoFocus={props.focus ? true : false}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="padding_horizontal header_top_right">
                <button type="button" class="btn mobile_header_search">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
                <ul>
                  <li>
                    {isloggedin ? (
                      // <Link onClick={handleLogout} ><i class="fa-solid fa-sign-out"></i></Link>
                      <Link to="/myprofile" ><i class="fa-solid fa-user"></i></Link>
                    ) :
                      (
                        <Link to="/login"><i class="fa-solid fa-user"></i></Link>
                      )}
                  </li>

                  {isloggedin ? (
                    // <Link onClick={handleLogout} ><i class="fa-solid fa-sign-out"></i></Link>
                    <li><Link to="/myprofile" ><i class="fa-solid fa-bell"></i></Link></li>
                  ) : ''}



                  <li><a href="javascript:void(0);"><i class="fa-solid fa-heart"></i></a></li>
                  <li><Link to="/cart"><i class="fa-solid fa-cart-shopping"></i></Link>
                    {/* { carts.data.length >= 1 ? (
              <span class="cart-count">{ carts.data.length }</span>
              ): ( '' )}  */}
                  </li>
                </ul>
              </div>
            </div>
            {/* New Menu Start */}
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Dress"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                     {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                          Dress
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Facial"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Facial
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Electronics"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        	Fan
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Test
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Office"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Storage/Display
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Office Seating
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        	Desks
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Outdoor"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        	Fire Pits
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Ottomons & Benches
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Occasional Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Sectionals
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Sofas
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Décor"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Lighting
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Swings
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Sculptures
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Rugs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Pillows
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Mirrors
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Fireplaces
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bedding
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Bedroom"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Mattresses
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Mirrors
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Youth
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dressers & Chests
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Nightstands
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Dining"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Benches
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bar Carts
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bars
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Stools
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Display & China Cabinets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Sideboards & Buffets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Living"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Media Storage
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bookcases & Shelving
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Console Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Accent & End Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Storage Cabinets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Coffee Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Furniture"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    {/*<Dropdown.Header>
                      <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> 
                      {"  "}
                      Dress
                    </Dropdown.Header>*/}
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        	Three Seater Sofa
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
          </div>
        </div>
      </header>

      {/* <div class="clearfix"></div> */}
    </>
  );
}
