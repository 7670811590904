/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import { is_logged_in } from "../utilities/auth";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import Swal from "sweetalert2";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Checkout = (props) => {
  const [loading, setLoading] = useState(true);
  const [carts, setCartData] = useState([]);
  const [addresses, setAddressData] = useState([]);
  const router = useHistory();
  const [paymentMethod, setPaymentMethod] = useState('COD');
  const [formData, setFormData] = useState({
    name: "",
    mobile_no: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postcode: "",
    landmark: "",
  });
  const handleCheckboxChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const validateForm = () => {
    if (!formData.name) {
      setFormData({ ...formData, name_error: "Name cannot be blank" });
    } else if (!formData.mobile_no && formData.mobile.length !== 10) {
      setFormData({ ...formData, mobile_no_error: "Mobile no must contain 10 digit" });
    } else if (!formData.address1) {
      setFormData({ ...formData, address1_error: "Address cannot be blank" });
    } else if (!formData.city) {
        setFormData({ ...formData, city_error: "City cannot be blank" });
    } else if (!formData.address2) {
    setFormData({ ...formData, address2_error: "Address cannot be blank" });
    } else if (!formData.state) {
    setFormData({ ...formData, state_error: "State cannot be blank" });
    } else if (!formData.postcode) {
    setFormData({ ...formData, postcode_error: "Postcode cannot be blank" });
    } else {
      return true;
    }
    return false;
  };
  const [errorData, setErrorData] = useState({
    name: [],
    address1: [],
    mobile_no: [],
  });

  useEffect(async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/cart-data`;
    let user_id = localStorage.getItem("user");
    let data = {user_id:user_id};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    setCartData(responseJson);

    const surl = `${pxy_url + API_URL}/customer-addresses`;
    let sdata = {user_id:user_id};

    const sresponse = await fetch(surl, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(sdata),
    });

    const sresponseJson = await sresponse.json();
    // console.log(sresponseJson);
    setAddressData(sresponseJson);
    setLoading(false);
  }, []);
  const removeCart = async () => {
    setLoading(true);
    if (paymentMethod === 'COD') {
      const url = `${pxy_url + API_URL}/checkout-order`;
      let paramss = props.match.params;
      let user_id = localStorage.getItem("user");
      // let quantity = this.state.value;
      // console.log(quantity);
      let data = { user_id:user_id};

      const response = await fetch(url, {
        method: "POST",
        headers: {
          // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin" : "*",
        },
        body: JSON.stringify(data),
      });

      const responseJson = await response.json();
      // console.log(responseJson);
      if (responseJson.status === 1) {
          
          setLoading(false);
          Swal.fire({
            title: "Order Placed Successfully!",
            icon: "success",
            confirmButtonText: "OK!",
          });
          setTimeout(() => {
              router.push("/");
          }, 2000);
        // Device Binding Checking end
      } else {
          setLoading(false);
        // console.log("logged in failed", response.status, responseJson);

        Swal.fire({
          title: `Error!`,
          text: `Please try after sometime`,
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
    } else if (paymentMethod === 'CCAvenue') {
      const merchantId = '2899292';
      const accessCode = 'AVHR17KJ53AC56RHCA';
      const orderId = generateRandomAlphanumeric(10);
      const amount = 45000; 

      try {
        const response = await axios.post('https://test.ccavenue.com/', {
          // Include necessary parameters
          // Note: These parameters may vary based on CC Avenue's API documentation
          merchant_id: merchantId,
          access_code: accessCode,
          order_id: orderId,
          amount: amount,
          // Include additional required parameters
        });

        // Handle the response, redirect, or perform other actions as needed
        console.log('Payment Response:', response.data);
      } catch (error) {
        // Handle errors
        console.error('Payment Error:', error);
      }
    }
    setLoading(false);
}
const generateRandomAlphanumeric = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }

  return result;
};
const addressSave = async (e) => {
    e.preventDefault();
    var errors = {
        name_error: "",
        mobile_no_error: "",
        address1_error: "",
        address2_error: "",
        city_error: "",
        state_error: "",
        postcode_error: "",
        landmark_error: "",
      };
  
    setFormData({ ...formData, ...errors });
    let user_id = localStorage.getItem("user");
    var data = {
      name: formData.name,
      mobile_no: formData.mobile_no,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      postcode: formData.postcode,
      landmark: formData.landmark,
      user_id: user_id,
    };

    if (validateForm()) {
      setLoading(true);
      const response = await postRequest(
        `/add-address`,
        JSON.stringify(data),
        "POST"
      );
      setLoading(false);
      if (!response) return;
      const responseJson = await response.json();
      console.log("RESP", responseJson, response);

      // console.log(responseJson);
      if (responseJson.status === 1) {
        setErrorData({
          name: [],
          email: [],
          mobile: [],
        });
        // toast.success("Account created successfully!")
        setFormData({
          name: "",
          mobile_no: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          postcode: "",
          landmark: "",
        });
        Swal.fire({
            title: "Address saved successfully!",
            icon: "success",
            confirmButtonText: "OK!",
        });
        setTimeout(() => {
            router.push("/checkout");
        }, 1000);
      } else {
        Swal.fire({
            title: "Some error occured! Please try again later.",
            icon: "error",
            confirmButtonText: "OK!",
        });
      }
    }
    setLoading(false);
} 
  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={70} />
      </Override>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Cart</title>
        </Helmet>
        <Header />
        <div class="clearfix"></div>
	
                <section class="section cart_details_sec checkout_details_sec">
                <div class="container">
                    <div class="row">
                        <div class="inner_page_main_part cart_page checkout_page">
                            <div class="cart_page_left checkout_page_left">
                                <div class="checkout_shipping">
                                    <div class="shipping_wrap" id="shipping_address_wrap">
                                        <div class="checkout_page_label_name">
                                            <label>Shipping To:</label>
                                            {/* <a href="javascript:void(0);" onclick="Chekcout_Show()">Edit</a> */}
                                        </div>
                                        
                                        {addresses.data.map((address, index) => (
                                         <div class="shipping_adddress">
                                            <h4>{ address.name+', '+address.mobile_no+', '+address.address1+', '+address.address2+', '+address.city+', '+address.state+', '+address.country+', '+address.landmark+', '+address.postcode}</h4>
                                        </div> 
                                        ))}
                                    </div>
                                    <div class="shipping_adddress_edit" id="shipping_adddress_edit">
                                        {/* <div class="checkout_page_label_name">
                                            <label><b>EDIT ADDRESS</b></label>
                                        </div> */}
                                        <div class="current_location">
                                            <button class="current_location_use"> Add Address</button>
                                        </div>
                                        <div class="checkout_edit_form">
                                            <div class="checkout_form">
                                                <form onSubmit={(e) => addressSave(e)} method="post">
                                                    <div class="fields fields--2">
                                                    <label class="field">
                                                        <span class="field__label" for="firstname">First name</span>
                                                        <input class="field__input" type="text" id="name" name="name"/>
                                                    </label>
                                                    <label class="field">
                                                        <span class="field__label" for="lastname">Mobile No</span>
                                                        <input class="field__input" type="text" id="mobile_no" name="mobile_no"/>
                                                    </label>
                                                    </div>
                                                    <label class="field">
                                                    <span class="field__label" for="address">Address Area and Street</span>
                                                    <input class="field__input" type="text" id="address1" name="address1" />
                                                    </label>
                                                    <label class="field">
                                                    <span class="field__label" for="address">Locality</span>
                                                    <input class="field__input" type="text" id="address2" name="address2" />
                                                    </label>
                                                    <label class="field">
                                                        <span class="field__label" for="city">City</span>
                                                        <input class="field__input" type="text" id="city" name="city" />
                                                    </label>
                                                    <label class="field">
                                                        <span class="field__label" for="state">State</span>
                                                        <select class="field__input" id="state">
                                                        <option value="West Bengal">West Bengal</option>
                                                        </select>
                                                    </label>
                                                    <div class="fields fields--3">
                                                    <label class="field">
                                                        <span class="field__label" for="zipcode">Pin code</span>
                                                        <input class="field__input" type="text" id="postcode" name="postcode"/>
                                                    </label>
                                                    <label class="field">
                                                        <span class="field__label" for="zipcode">Landmark</span>
                                                        <input class="field__input" type="text" id="landmark" name="landmark"/>
                                                    </label>
                                                    
                                                    
                                                    </div>
                                                    <div class="check_out_form_button">
                                                        <input type="submit" class="check_out_form_button_save" value="Save" />
                                                        <a href="javascript:void(0);" class="check_out_form_button_cancel" onclick="Chekcout_Hide()">Cancel</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="checkout_shipping">
                                    <div class="shipping_wrap">
                                        <div class="checkout_page_label_name">
                                            <label>payment details:</label>
                                            <a href="javascript:void(0);">Edit</a>
                                        </div>
                                        <div class="shipping_adddress">
                                        <label>
                                          <input
                                            type="checkbox"
                                            value="COD"
                                            checked={paymentMethod === 'COD'}
                                            onChange={handleCheckboxChange}
                                          />
                                          Cash on Delivery
                                        </label>

                                        <label>
                                          <input
                                            type="checkbox"
                                            value="CCAvenue"
                                            checked={paymentMethod === 'CCAvenue'}
                                            onChange={handleCheckboxChange}
                                          />
                                          CC Avenue
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="product_box_cart_sec">
                                    <div class="checkout_page_label_name">
                                        <label>Your order:</label>
                                    </div>
                                    {carts.data.map((crt, index) => (
                                    <div class="product_box_cart">
                                        <div class="product_box_cart_top">
                                            <div class="product_box_cart_top_left">
                                             <img src={ carts.imagePath + crt.product_image} />
                                            </div>
                                            <div class="product_box_cart_top_right">
                                                <div class="product_box_cart_top_right_one">
                                                    <h3>{crt.name}</h3>
                                                    <span>Product Category: {crt.product_category_name}</span>
                                                </div>
                                                <div class="product_box_cart_top_right_bottom">
                                                    <div class="product_box_cart_top_right_bottom_left">
                                                        {/* <span onClick='decreaseCount(event, this)'>-</span> */}
                                                        <input type="text" class="product_quantity_field" value={crt.qty}/>
                                                        {/* <span onClick='increaseCount(event, this)'>+</span> */}
                                                    </div>
                                                    <div class="product_box_cart_top_right_bottom_right">
                                                        <span>&#8360; {crt.special_price * crt.qty}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product_box_cart_bottom">
                                            <div class="product_box_cart_bottom_part">
                                                <a href="javascript:void(0);">
                                                    <i class="fa-solid fa-trash-can"></i>
                                                    <span>Remove</span>
                                                </a>
                                            </div>
                                            <div class="product_box_cart_bottom_part move_to_wishlist">
                                                <a href="javascript:void(0);">
                                                    <i class="fa-solid fa-heart"></i>
                                                    <span>move to wishlist</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            <div class="cart_page_right checkout_page_right">
                                <div class="product_box_cart_order">
                                    <h2>Price details</h2>
                                    <div class="add_discount_code">
                                        <a href="javascript:void(0);">
                                            <i class="fa-light fa-plus"></i>
                                            <span>Add a discount code</span>
                                        </a>
                                    </div>
                                    <div class="cart_totals">
                                        <div class="totals_item">
                                        <label>Subtotal</label>
                                        <span>&#8360; {carts.subtotal}</span>
                                        </div>
                                        <div class="totals_item">
                                        <label>Estimated Shipping</label>
                                        <span>&#8360; 0</span>
                                        </div>
                                        <div class="totals_item">
                                        <label>Estimated Tax</label>
                                        <span>&#8360; 0</span>
                                        </div>
                                        <div class="totals_item total_amout">
                                        <label>Total</label>
                                        <span>&#8360; {carts.subtotal}</span>
                                        </div>
                                    </div>
                                    <div class="checkout_button">
                                        <button type="button" class="proceed_checkout" onClick={removeCart}>Proceed to Checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

                <div class="clearfix"></div>
        
        <Footer />
      </div>
    );
  }
};

export default Checkout;
